import * as React from "react"

export function ExplainerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
      <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
        <path
            d="M24 4C12.972 4 4 12.972 4 24c0 3.275.863 6.335 2.262 9.064l-2.168 7.764c-.505 1.804 1.278 3.585 3.082 3.08l7.767-2.168C17.671 43.137 20.727 44 24 44c11.028 0 20-8.972 20-20S35.028 4 24 4zm0 3c9.406 0 17 7.594 17 17 0 9.406-7.594 17-17 17-3.003 0-5.808-.782-8.256-2.146a1.5 1.5 0 00-1.135-.135L7.223 40.78l2.062-7.383a1.5 1.5 0 00-.135-1.134A16.89 16.89 0 017 24c0-9.406 7.594-17 17-17zm-.023 5.979A1.5 1.5 0 0022.5 14.5v12a1.5 1.5 0 103 0v-12a1.5 1.5 0 00-1.523-1.521zM24 31a2 2 0 000 4 2 2 0 000-4z"/>
      </svg>
  )
}
